import {Button} from "antd";
import {FaArrowRightLong} from "react-icons/fa6";
import {Link} from "react-router-dom";

export const ThanksPage = () => {
  return(
    <div className='thanksPage'>
      <h1 style={{textTransform: 'uppercase', textAlign: 'center', fontSize: '10vh', animation: 'thanksAppear 5s forwards'}}>Спасибо!</h1>
      <div style={{textTransform: 'uppercase', textAlign: 'center', fontSize: '3vh', marginBottom: '2vh', color: 'lightgray'}}>Ваша заявка на проект успешна отправлена и в скором времени Вам перезвонят с ответом.</div>
      <div style={{textTransform: 'uppercase', textAlign: 'center', fontSize: '4vh', color: 'lightgray'}}>А пока, Вы можете ещё раз посмотреть наши проекты</div>
      <Link to='/portfolio'><Button className='showPortfolioBtn' style={{marginBottom: '20vh'}} type='primary'> Смотреть порфтолио<FaArrowRightLong /> </Button></Link>
    </div>
  );
};

export default ThanksPage;