import {Button, Form, Modal, Input} from "antd";
import {ArrowRightOutlined} from '@ant-design/icons';
import { Link } from "react-router-dom"
import {useEffect, useRef, useState} from "react";
import { useLocation } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import TextArea from "antd/es/input/TextArea";
import {useNavigate} from "react-router";

export const Header = () => {
  const navigate = useNavigate();
  const refScrollTop = useRef(null);
  const { pathname } = useLocation();
  const [phone, setPhone] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    let lastScroll = 0;
    const defaultOffset = 200;
    const menu = document.querySelector('.menu');

    const scrollPosition = () => window.pageYOffset || document.documentElement.scrollTop;
    const containHide = () => menu.classList.contains('hide');

    window.addEventListener('scroll', () => {

      if(scrollPosition() > lastScroll && !containHide() && scrollPosition() > defaultOffset){
        menu.classList.add('hide');
        //scroll down
      } else if(scrollPosition() < lastScroll && containHide()) {
        menu.classList.remove('hide');
        //scroll up
      }

      lastScroll = scrollPosition();
    })
  },[pathname]);

  let scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  };

  const handleChange = (value) => {
    setPhone(value);
  };

  const onFinish = (values) => {
    setIsModalOpen(false);
    console.log('Success:', values);
    navigate('/thanks');
  };
  const onFinishFailed = (errorInfo) => {
    setIsModalOpen(true);
    console.log('Failed:', errorInfo);
  };

  return(
    <div className='menu' ref={refScrollTop}>
      <div> {/* div, чтобы разделить текст и логотип */}
        {/* свойства кнопки antd (не работают)*/}
        <Link to='/'><Button className='buttonMenu' type='text' onClick={scrollToTop}>Главная</Button></Link>
        <Link to='/portfolio'><Button className='buttonMenu' type='text'>Порфтолио</Button></Link>
        <Link to='contacts'><Button className='buttonMenu' type='text'>Контакты</Button></Link>
      </div>
      <div className='logoContainer'>
        <img src='/img/Other/Logo.png' alt='logo' style={{borderRadius: '0', width: '15vh', padding: '1vh', margin: 0}}/>
        <h4 style={{margin: 0, padding: 0}}>Ваша надёжная рекламно-производственная компания!</h4>
      </div>
      <div> {/* div, чтобы разделить текст и логотип */}
        <span style={{color: 'white', fontSize: '1.7vh'}}>+7(915) 418-91-21</span>
        <Button
          className='buttonMenu'
          type='text'
          onClick={showModal}
        >
          Обсудить проект <ArrowRightOutlined />
        </Button>
      </div>
      <Modal
        style={{marginRight: '40vh'}}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <h1>Оставьте заявку на свой проект</h1>
        <Form
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          style={{
            maxWidth: 600,
          }}
          initialValues={{
            remember: true,
            "select": "a1"
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <span style={{color: 'gray'}}>Ваше имя</span>
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: 'Пожалуйста, введите имя!',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <span style={{color: 'gray'}}>Ваш e-mail</span>
          <Form.Item
            name="e-mail"
            rules={[
              {
                required: true,
                type: 'email',
                message: 'Пожалуйста, введите e-mail!',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <span style={{color: 'gray'}}>Ваш номер телефона</span>
          <Form.Item
            name="phone"
            rules={[
              {
                required: true,
                min: 11,
                message: 'Пожалуйста, введите корректный номер телефона!'
              },
            ]}
          >
            <PhoneInput
              placeholder='+'
              value={phone}
              onChange={handleChange}
              country="ru"
            />
          </Form.Item>

          <span style={{color: 'gray'}}>Ваш комментарий</span>
          <Form.Item
            name="comment"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <TextArea autoSize={{
              minRows: 3,
              maxRows: 5,
            }}/>
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Button className='bidBtnModal' type='primary' htmlType='submitButton'>Оставить заявку</Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Header;