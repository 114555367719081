// import exampleOfWork1 from '../../../public/img/Миниатюры на главной/1.JPG';
// import exampleOfWork2 from '../../../public/img/Миниатюры на главной/2.jpg';
// import exampleOfWork3 from '../../../public/img/Миниатюры на главной/3.JPG';
// import exampleOfWork4 from '../../../public/img/Миниатюры на главной/4.JPG';
// import exampleOfWork5 from '../../../public/img/Миниатюры на главной/5.JPG';
// import exampleOfWork6 from '../../../public/img/Миниатюры на главной/6.JPG';
// import exampleOfWork7 from '../../../public/img/Миниатюры на главной/7.jpg';

export const Introduction = () => {

  return(
    <div className='introduction'>
      <h1>Изготовление и монтаж декораций</h1>
      <h5 style={{margin: 0, color: '#cacaca', fontSize: '1.6vh', textAlign: 'center'}}>Полный цикл работ:<br />от проектировки до изготовления наружной рекламы</h5>
      <div className='dividingLine' />
      <div className='examplesOfWork'>
        <img className='exampleOfWork' src='/img/miniaturesOnHomePage/1.JPG' id='exampleOfWork1' alt='Пример работы'/>
        <img className='exampleOfWork' src='/img/miniaturesOnHomePage/2.jpg' id='exampleOfWork2' alt='Пример работы'/>
        <img className='exampleOfWork' src='/img/miniaturesOnHomePage/3.JPG' id='exampleOfWork3' alt='Пример работы'/>
        <img className='exampleOfWork' src='/img/miniaturesOnHomePage/4.JPG' id='exampleOfWork4' alt='Пример работы'/>
        <img className='exampleOfWork' src='/img/miniaturesOnHomePage/5.JPG' id='exampleOfWork5' alt='Пример работы'/>
        <img className='exampleOfWork' src='/img/miniaturesOnHomePage/6.JPG' id='exampleOfWork6' alt='Пример работы'/>
        <img className='exampleOfWork' src='/img/miniaturesOnHomePage/7.jpg' id='exampleOfWork7' alt='Пример работы'/>
      </div>
      <h1 className='nameOfCompany'>DecorConcept</h1>
    </div>
  );
};

export default Introduction;