import {useEffect} from "react";

const Contacts = () => {

  useEffect(() => {
    document.title = "Контакты";
  }, []);

  return(
    <div className='contacts'>
      <h1 style={{textAlign: 'center', fontSize: '4vh'}}>Контакты</h1>

      <div className='contactsFooter'>
        <div>
          <div className='footer_number'>
            +7(915) 418-91-21
          </div>
          <div className='footer_numberText'>
            Контактный телефон
          </div>
        </div>
        <div>
          <div className='footer_e-mail'>
            example@gmail.com
          </div>
          <div className='footer_e-mailText'>
            E-mail
          </div>
        </div>
      </div>
    </div>
  )
};

export default Contacts;