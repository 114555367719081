// import exampleOfWork1 from '../../../public/img/афиша работ/IMG_0449 2.JPG';
// import exampleOfWork2 from '../../../public/img/armiya_strelc_compl_voin/IMG_0976 2.JPG';
import { FaArrowRightLong } from "react-icons/fa6";
import {Button} from "antd";
import {useEffect} from "react";
import {Link} from 'react-router-dom';

export const Description = () => {

  let options = {
    root: null,
    rootMargin: '5px',
    threshold: 0.5
  }

  useEffect(() => {

    let callback = function (entries, observer) {
      entries.forEach(entry => {
        if(entry.isIntersecting) {
          entry.target.classList.add('active');
        }
      })
    }

    let observer = new IntersectionObserver(callback, options);

    let targets = document.querySelectorAll('.anim')
    targets.forEach(target => {
      observer.observe(target);
    });
  });

  return(
    <div className='description'>
      <h1 style={{fontSize: '4vh', textAlign: 'center'}}>О КОМПАНИИ<br />ПО ИЗГОТОВЛЕНИЮ НАРУЖНОЙ РЕКЛАМЫ DecorConcept</h1>
      <div className='contentOfDescription'>
        <img src='/img/afisha_of_works/IMG_0449 2.JPG' alt='фото работы' className='anim picture' id='pic1'/>
        <div style={{maxWidth: '40vh', padding: '3vh', height: 'auto'}}>
          <span>Мы гордимся превращением идей в реальность.
          С нашим многолетним опытом мы обеспечим техническую поддержку мероприятий и их декор.</span><br />
          <Link to='/portfolio'><Button className='showPortfolioBtn' type='primary'> Смотреть порфтолио<FaArrowRightLong /> </Button></Link>
        </div>
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
          <div style={{maxWidth: '50vh', height: 'auto', display: 'flex', flexDirection: 'row', alignItems: 'flex-start'}}>
            <div style={{width: 'auto', height: 'auto'}}>Мы создаём современные декорации, бутафорию, пресс-воллы и фотозоны, а также производим торговую и выставочную мебель.</div>
            <img src='/img/armiya_strelc_compl_voin/IMG_0976 2.JPG' alt='фото работы' className='anim picture' id='pic2'/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Description;