import {Button, Col, Row} from "antd";
import {useEffect} from "react";
import {Link} from "react-router-dom";

export const Footer = () => {

  let options = {
    root: null,
    rootMargin: '5px',
    threshold: 0.5
  }

  useEffect(() => {

    let callback = function (entries, observer) {
      entries.forEach(entry => {
        if(entry.isIntersecting) {
          entry.target.classList.add('active');
        }
      })
    }

    let observer = new IntersectionObserver(callback, options);

    let targets = document.querySelectorAll('.dividingLineFooter')
    targets.forEach(target => {
      observer.observe(target);
    });
  });

  let scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  };

  return(
    <Row>
      <Col span={24}>
      <div className='footer'>
        <div className='contacts'>
          <div className='dividingLineFooter' />
          <Link to='/'><Button className='buttonMenu' style={{fontSize: '4vh'}} type='text' onClick={scrollToTop}>Главная</Button></Link>
          <Link to='/portfolio'><Button className='buttonMenu' style={{fontSize: '4vh'}} type='text'>Порфтолио</Button></Link>
          <Link to='/contacts'><Button className='buttonMenu' style={{fontSize: '4vh'}} type='text'>Контакты</Button></Link>
          <div className='dividingLineFooter' />
        </div>
        <div className='contactsFooter'>
          <div>
            <div className='footer_number'>
              +7(915) 418-91-21
            </div>
            <div className='footer_numberText'>
              Контактный телефон
            </div>
          </div>
          <div>
            <div className='footer_e-mail'>
              example@gmail.com
            </div>
            <div className='footer_e-mailText'>
              E-mail
            </div>
          </div>
        </div>
        <div className='dividingLineFooter'/>
        <div className='urInfo'>
          <div style={{padding: '1vh', color: 'gray'}}>© 2024. Рекламно-производственная «DecorConcept»</div>
        </div>
      </div>
      </Col>
    </Row>
  );
};