import Introduction from "./elementsOnContentPage/Introduction";
import Description from "./elementsOnContentPage/Description";
import MoreInformation from "./elementsOnContentPage/MoreInformation";
import {useEffect} from "react";

export const Content = () => {

  useEffect(() => {
    document.title = "Главная";
  }, []);

  return(
    <>
      <Introduction />
      <Description />
      <MoreInformation />
    </>
  );
};

export default Content;