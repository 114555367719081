// import Details from '../../../public/img/Остальное/Детали.png';
// import Money from '../../../public/img/Остальное/Бюджет.png';
// import Air from '../../../public/img/Остальное/ПримерЛюбТОчкаМира.png';
import {useEffect} from "react";
import {Col, Image, Typography, Row} from 'antd';

//1
// import work1 from '../../../public/img/Картинки из MoreInformation/1.JPG';
// import work2 from '../../../public/img/Картинки из MoreInformation/11.JPG';

//2
// import work3 from '../../../public/img/Картинки из MoreInformation/2.JPG';
// import work4 from '../../../public/img/Картинки из MoreInformation/22.JPG';
// import work5 from '../../../public/img/Картинки из MoreInformation/222.JPG';

//3
// import work6 from '../../../public/img/Картинки из MoreInformation/3.JPG';

//4
// import work7 from '../../../public/img/Картинки из MoreInformation/4.JPG';

//5
// import work8 from '../../../public/img/Картинки из MoreInformation/5.JPG';
// import work9 from '../../../public/img/Картинки из MoreInformation/55.JPG';
// import work10 from '../../../public/img/Картинки из MoreInformation/555.JPG';

export const MoreInformation = () => {

  const infoMore = [
    {
      name: 'Специализируемся на реализации стендов любой сложности. Опираясь на многолетний опыт, ' +
        'подберем максимально эффективное и технологичное решение для реализации проекта .',
      items: [
        '/img/moreInformationImg/1.JPG',
        '/img/moreInformationImg/11.JPG'
      ]
    },
    {
      name: 'Фотозоны, арт-объекты и пресс-воллы',
      items: [
        '/img/moreInformationImg/2.JPG',
        '/img/moreInformationImg/22.JPG',
        '/img/moreInformationImg/222.JPG'
      ]
    },
    {
      name: 'Cветовые вывески',
      items: [
        '/img/moreInformationImg/3.JPG',
      ]
    },
    {
      name: 'Широкоформатная печать на высококачественном оборудовании, плоттерная резка и фрезеровка различных ' +
        'материалов, оформление мероприятий ,ивентов и шоу',
      items: [
        '/img/moreInformationImg/4.JPG',
      ]
    },
    {
      name: 'Изготовление и брендирование металлоконструкций',
      items: [
        '/img/moreInformationImg/5.JPG',
        '/img/moreInformationImg/55.JPG',
        '/img/moreInformationImg/555.JPG'
      ]
    },
  ];

  let options = {
    root: null,
    rootMargin: '5px',
    threshold: 0.5
  }

  useEffect(() => {

    let callback = function (entries, observer) {
      entries.forEach(entry => {
        if(entry.isIntersecting) {
          entry.target.classList.add('active');
        }
      })
    }

    let observer = new IntersectionObserver(callback, options);

    let targets = document.querySelectorAll('.pictureInfo');

    targets.forEach(target => {
      observer.observe(target);
    });

    let observer2 = new IntersectionObserver(callback, options);

    let targets2 = document.querySelectorAll('.dividingLineMoreInf');

    targets2.forEach(target => {
      observer2.observe(target);
    });
  });

  const info = [
    {
      src: '/img/Other/Details.png',
      text: 'Не беспокойтесь о деталях — мы сами решаем все вопросы, связанные с площадкой.Оперативная реакция и' +
        'решение любых возникших вопросов — наше второе имя. Мы готовы реализовать ваш проект даже в самые сжатые сроки.'
    },
    {
      src: '/img/Other/Budget.png',
      text: 'Подбираем решения под ваш бюджет и предлагаем максимально эффективные технологии ' +
        +'реализации проектов любой сложности..'
    },
    {
      src: '/img/Other/Air.png',
      text: 'Монтаж стендов в любой точке мира. С нами вы можете быть уверены, что ваш выставочный стенд ' +
        'будет готов в срок, где бы вы ни находились.'
    }
  ];

  // return(
  //   <div className='moreInformation'>
  //
  //     <div className='underMoreInf'>
  //
  //       <div className='dividingLineMoreInf'/>
  //
  //       {
  //         infoMore.map(el => {
  //           return(
  //             <div className='elementOfEvents' key={el.name}>
  //               <Image.PreviewGroup
  //                 items={
  //                   el.items
  //                 }
  //               >
  //                 <Image
  //                   width={400}
  //                   src={el.items[0]}
  //                 />
  //               </Image.PreviewGroup>
  //               <h2 style={{width: '75vh', textAlign: 'center', wordBreak: 'normal'}}>{el.name}</h2>
  //             </div>
  //           );
  //         })
  //       }
  //
  //       {
  //         info.map(el => {
  //           return(
  //             <div key={el.text}>
  //               <div className='info'>
  //                 <img src={el.src} alt='' className='pictureInfo'/>
  //                 <div className='textInfo'>{el.text}</div>
  //               </div>
  //             </div>
  //           );
  //         })
  //       }
  //
  //       <h1 style={{textAlign: 'center', padding: '4vh', marginTop: '15vh'}}>Выбирайте качество, надежность и
  //         профессионализм - <br/>нашу рекламно-производственную компанию DecorConcept!</h1><br/>
  //
  //     </div>
  //       <div className='parallax' />
  //   </div>
  // );

  return (
    <div className='moreInformation'>
      <div className='underMoreInf'>
        <div className='dividingLineMoreInf'/>
        <Row gutter={[15,15]} style={{ padding: '10% 20% 5% 20%', margin: 0}}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Image src={'/img/moreInformationImg/1.png'} style={{ borderRadius: '5%'}} className={'pictureInfo'}/>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Image src={'/img/moreInformationImg/11.JPG'} style={{ borderRadius: '5%'}} className={'pictureInfo'}/>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Typography.Title style={{ textAlign: "center"}}>
              Специализируемся на реализации стендов любой сложности. Опираясь на
              многолетний опыт, подберем максимально эффективное и технологичное
              решение для реализации проекта.
            </Typography.Title>
          </Col>
        </Row>
        <Row gutter={[15,15]} align={'stretch'} style={{ padding: '0 20% 5% 20%', margin: 0}}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Image src={'/img/moreInformationImg/22.JPG'} style={{ borderRadius: '5%'}} className={'pictureInfo'}/>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Image src={'/img/moreInformationImg/222.JPG'} style={{ borderRadius: '5%'}} className={'pictureInfo'}/>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Typography.Title style={{ textAlign: "center"}}>
              Фотозоны, арт-объекты и пресс-воллы.
            </Typography.Title>
          </Col>
        </Row>

        <Row gutter={[15,15]} style={{ padding: '0 20% 5% 20%', margin: 0}}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Image src={'/img/moreInformationImg/2.png'} style={{ borderRadius: '5%'}} className={'pictureInfo'}/>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Image src={'/img/moreInformationImg/3.png'} style={{ borderRadius: '5%'}} className={'pictureInfo'}/>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Typography.Title style={{ textAlign: "center"}}>
              Световые вывески
            </Typography.Title>
          </Col>
        </Row>

        <Row gutter={[15,15]} style={{ padding: '0 20% 5% 20%', margin: 0}}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Image src={'/img/neft_magistral/IMG_5541.JPG'} style={{ borderRadius: '5%'}} className={'pictureInfo'}/>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Image src={'/img/afisha_of_works/IMG_3855.JPG'} style={{ borderRadius: '5%'}} className={'pictureInfo'}/>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Typography.Title style={{ textAlign: "center"}}>
              Широкоформатная печать на высококачественном оборудовании,плоттерная резка и фрезеровка различных материалов
            </Typography.Title>
          </Col>
        </Row>

        <Row gutter={[15,15]} style={{ padding: '0 20% 5% 20%', margin: 0}}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Image src={'/img/moreInformationImg/5.JPG'} style={{ borderRadius: '5%'}} className={'pictureInfo'}/>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Image src={'/img/moreInformationImg/55.png'} style={{ borderRadius: '5%'}} className={'pictureInfo'}/>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Typography.Title style={{ textAlign: "center"}}>
              Оформление мероприятий ,ивентов и шоу
            </Typography.Title>
          </Col>
        </Row>

        <Row gutter={[15,15]} style={{ padding: '0 20% 5% 20%', margin: 0}}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Image src={'/img/veykserf_club_GELENWOOD/IMG_9820.JPG'} style={{ borderRadius: '5%'}} className={'pictureInfo'}/>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Image src={'/img/veykserf_club_GELENWOOD/IMG_9886.JPG'} style={{ borderRadius: '5%'}} className={'pictureInfo'}/>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Typography.Title style={{ textAlign: "center"}}>
              Изготовление и брендирование металлоконструкций
            </Typography.Title>
          </Col>
        </Row>

      </div>
    </div>
  )
};

export default MoreInformation;