import Header from './components/Header';
import Content from "./components/Content";
import {Footer} from "./components/Footer";
import { Routes, Route } from "react-router-dom"
import Portfolio from "./components/pages/Portfolio";
import Contacts from "./components/pages/Contacts";
import {useEffect} from "react";
import ThanksPage from "./components/pages/ThanksPage";

function App() {

  useEffect(() => {
    document.title = "Главная";
  }, []);

  return (
    <div className='App'>
      <Header/> <br /> <br />
      <Routes>
        <Route path='/' element={<Content />}/>
        <Route path='/portfolio' element={<Portfolio />}/>
        <Route path='/contacts' element={<Contacts />}/>
        <Route path='/thanks' element={<ThanksPage />}/>
      </Routes >
      <Footer />
    </div>
  );
}

export default App;
